<template>
  <div id="allapp">
    <div class="setLogon">
      <div class="form-list">
        <div class="item">
          <div class="text">
            <span>用户名称</span>
          </div>
          <div class="content">
            <input type="text" v-model="nickName" placeholder="请输入用户名称">
          </div>
        </div>
        <div class="item img">
          <div class="text">
            <span>用户头像</span>
          </div>
          <div class="content " @click="handleImage()">
            <img class="mainBorder2" :src="avatar" v-if="avatar" />
            <img class="mainBorder2" :src="defaultUserImg" v-else />
          </div>
        </div>
      </div>
    </div>

    <div class="submitBut" @click="submit()">提交</div>

  </div>
</template>

<script>
import { getToken, uploadPhoto } from '../../api/index'
import { mapState, mapActions } from 'vuex'
import theUploadImg from '../../components/UploadImg'
const agentCustomize = require('@/utils/agentCustomize')


export default {
  name: 'EditUserinfo',
  data() {
    return {
      defaultUserImg: agentCustomize.defaultUserImg,
      nickName: '',
      avatar: '',
    }
  },
  computed: {
    ...mapState('user', ['custMsg']),
  },
  created() {
    // console.log(this.custMsg)
    this.nickName = this.custMsg.nickName
    this.avatar = this.custMsg.imgUrl
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    async submit() {
      let params = {
        trxCode: 'XF161',
        nickName: this.nickName,
      }

      // 图片上传
      if (this.isBase64(this.avatar)) {
        await uploadPhoto([this.avatar]).then((res) => {
          params.avatar = res.data[0]
        })
      }

      getToken(params).then((res) => {
        if (res.custMsg) {
          this.$toast.success('绑定成功')
          this.getUserInfo()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
    isBase64(str) {
      if (str.indexOf('data:') != -1 && str.indexOf('base64') != -1) {
        return true
      } else {
        return false
      }
    },
    handleImage() {
      theUploadImg().then((res) => {
        this.avatar = res
      })
    },
  },
}
</script>

<style lang='less' scoped>
.setLogon {
  width: 100%;

  .form-list {
    background: #fff;
    padding: 0 3vw;

    .item {
      padding: 4vw 2vw;
      border-bottom: 2px solid #f4f4f4;

      .text {
        display: inline-block;
        color: #333;
      }
      .content {
        float: right;
      }
    }
    .item.img {
      height: 12vw;
      .content {
        img {
          width: 12vw;
          height: 12vw;
          border-radius: 50%;
        }
      }
    }
  }

  .form-list .content input {
    border: none;
    text-align: right;
    color: #999;
  }
}
</style>